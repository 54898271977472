import Vue from 'vue'
import VueRouter, { NavigationGuard } from 'vue-router'
import Start from '../views/Start.vue'
import store from '../store'; // the vuex store

const url = process.env.NODE_ENV === 'development' ? '//localhost/' : '/'
store.commit('setBaseUrl', url)
const storeInit = () => store.dispatch('getAppInfo')
const defaultTitle = 'Bayerische Architektenkammer';

const ifNotAuthenticated: NavigationGuard<Vue> = (to, from, next) => {
  if (!store.state.user) {
    next();
    return;
  }
  next('/home');
};

const ifAuthenticated: NavigationGuard<Vue> = (to, from, next) => {
  document.title = to.meta?.title || defaultTitle; // set page titles
  if (store.state.user) {
    // TODO: check if token still valid
    let isExpired = false;
    let isInvalid = false;
    if (isExpired || isInvalid) {
      store.commit('setUser', null);
      next('/anmelden/login');
    } else {
      next();
    }
    return;
  }
  next('/anmelden/login');
};

const ifAdmin: NavigationGuard<Vue> = (to, from, next) => {
  document.title = to.meta?.title || defaultTitle; // set page titles
  if (store.state.user) {
    // TODO: check if token still valid
    let isExpired = false;
    let isInvalid = false;
    let noAdmin = true;
    if (store.state.user.roles && store.state.user.roles.includes('ROLE_ADMIN')) {
      noAdmin = false;
    }
    if (isExpired || isInvalid || noAdmin) {
      store.commit('setUser', null);
      next('/auth');
    } else {
      next();
    }
    return;
  }
  next('/auth');
};

const ifRole: NavigationGuard<Vue> = (to, from, next) => {
  document.title = to.meta?.title || defaultTitle; // set page titles
  if (store.state.user) {
    // TODO: check if token still valid
    let isExpired = false;
    let isInvalid = false;
    let noAccess = true;
    if (
      to.meta?.requiresRole &&
      store.state.user.roles &&
      (store.state.user.roles.includes('ROLE_ADMIN') || store.state.user.roles.includes(to.meta.requiresRole))
    ) {
      noAccess = false;
    }
    if (isExpired || isInvalid || noAccess) {
      store.commit('setUser', null);
      if (to.path.includes('/jury')) {
        next('/jury');
      } else {
        next('/presse');
      }
    } else {
      next();
    }
    return;
  }
  if (to.path.includes('/jury')) {
    next('/jury');
  } else {
    next('/presse');
  }
};

Vue.use(VueRouter)

const routes = [
  /* Start View */
  {
    path: '/',
    name: 'start',
    component: Start,
  },
  /* Anmelden View */
  {
    path: '/anmelden',
    component: () => import('../views/Anmelden.vue'),
    children: [
      {
        path: '',
        name: 'anmelden',
        component: () => import('../components/anmelden/Start.vue'),
      },
      {
        path: 'abmelden',
        component: () => import('../components/auth/Logout.vue'),
      },
      {
        path: 'info',
        component: () => import('../components/anmelden/Info.vue'),
      },
      {
        path: 'login',
        component: () => import('../components/anmelden/LoginForm.vue'),
      },
      {
        path: 'index',
        component: () => import('../components/anmelden/Index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'kontaktdaten',
        name: 'nutzerdaten',
        component: () => import('../components/anmelden/UserOfficeForm.vue'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  /* Projekt View */
  {
    path: '/projekt/:id',
    component: () => import('../views/Projekt.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'schritt/:part?',
        name: 'projekt',
        component: () => import('../components/projekt/DetailForm.vue'),
      },
      {
        path: 'vorschau',
        name: 'projektVorschau',
        component: () => import('../components/projekt/Vorschau.vue'),
      },
      {
        path: 'blatt',
        name: 'projektBlatt',
        component: () => import('../components/projekt/Vorschau.vue'),
      },
      {
        path: 'eingereicht',
        name: 'projektEingereicht',
        component: () => import('../components/projekt/Eingereicht.vue'),
      },
      {
        path: 'einreichen',
        name: 'projektEinreichen',
        component: () => import('../components/projekt/Einreichen.vue'),
      },
      {
        path: 'termin',
        name: 'projektTermin',
        component: () => import('../components/projekt/Termin.vue'),
      },
      {
        path: 'entfernen',
        name: 'projektEntfernen',
        component: () => import('../components/projekt/Entfernen.vue'),
      },
    ],
  },
  /* Auth View */
  {
    path: '/auth',
    component: () => import('../views/Auth.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/auth/LoginForm.vue'),
      },
      {
        path: 'abmelden',
        beforeEnter: ifAdmin,
        component: () => import('../components/auth/Logout.vue'),
      },
    ],
  },
  /* Admin View */
  {
    path: '/admin',
    component: () => import('../views/Admin.vue'),
    beforeEnter: ifAdmin,
    children: [
      {
        path: '',
        component: () => import('../components/admin/Index.vue'),
      },
      {
        path: 'liste/:type/:awardId?/:page?',
        name: 'crud-liste',
        component: () => import('../components/admin/CrudList.vue'),
      },
      {
        path: 'bearbeiten/:type/:id/:part?',
        name: 'crud-bearbeiten',
        component: () => import('../components/admin/CrudDetailForm.vue'),
      },
      {
        path: 'entfernen/:type/:id',
        name: 'crud-entfernen',
        component: () => import('../components/admin/CrudEntfernen.vue'),
      },
      {
        path: 'mailing/:awardId?',
        name: 'crud-mailing',
        component: () => import('../components/admin/CrudMailing.vue'),
      },
    ],
  },
  /* Press View */
  {
    path: '/presse',
    component: () => import('../views/Press.vue'),
    meta: {
      press: true
    },
    children: [
      {
        path: '',
        component: () => import('../components/auth/LoginForm.vue'),
      },
      {
        path: 'index',
        meta: { requiresRole: 'ROLE_PRESS' },
        component: () => import('../components/press/Index.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'projekte/:awardId',
        name: 'press-projects',
        meta: { requiresRole: 'ROLE_PRESS' },
        component: () => import('../components/press/ProjectList.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'projekt/:awardId/:id',
        name: 'press-detail',
        meta: { requiresRole: 'ROLE_PRESS' },
        component: () => import('../components/press/ProjectDetail.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'abmelden',
        component: () => import('../components/auth/Logout.vue'),
      },
    ],
  },
  /* Jury View */
  {
    path: '/jury',
    component: () => import('../views/Jury.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/auth/LoginForm.vue'),
      },
      {
        path: 'index',
        meta: { requiresRole: 'ROLE_JURY' },
        component: () => import('../components/jury/Index.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'projekte/:awardId/:phase?/:group?',
        name: 'jury-projects',
        meta: { requiresRole: 'ROLE_JURY' },
        component: () => import('../components/jury/JuryList.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'projekt/:awardId/:id/:phase?',
        name: 'jury-detail',
        meta: { requiresRole: 'ROLE_JURY' },
        component: () => import('../components/jury/JuryDetail.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'abmelden',
        component: () => import('../components/auth/Logout.vue'),
      },
    ],
  },
  /* Export View */
  {
    path: '/export',
    component: () => import('../views/Export.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/auth/LoginForm.vue'),
      },
      {
        path: 'index',
        meta: { requiresRole: 'ROLE_EXPORT' },
        component: () => import('../components/export/Index.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'liste/:awardId',
        name: 'exports',
        meta: { requiresRole: 'ROLE_EXPORT' },
        component: () => import('../components/export/ExportList.vue'),
        beforeEnter: ifRole,
      },
      {
        path: 'abmelden',
        component: () => import('../components/auth/Logout.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

// Before all other beforeEach
router.beforeEach(async (to, from, next) => {
  await (store as any).restored
  storeInit().then(() => next())
    .catch(e => console.log('e', e))
})

export default router
