export function checkCondition(field: any, key: string, formData: any) {

  // hacky and-condition-check with condition and conditions (and/or)
  if (field.conditions && field.condition) {

    const conditions = [field.condition]
    let matchAll = conditions.map((c: any) => _checkConditionsCollection(c, formData, key))
    var resultCondition = matchAll.filter((res: boolean) => res === false).length === 0

    const conditionsAll = field.conditions
    let matchAllConditions = conditionsAll.map((c: any) => _checkConditionsCollection(c, formData, key))
    let resultAllConditions
    if (field.conditionType === 'OR') {
      resultAllConditions = matchAllConditions.filter((res: boolean) => res === true).length > 0
    } else {
      resultAllConditions = matchAllConditions.filter((res: boolean) => res === false).length === 0
    }

    return resultCondition && resultAllConditions
  }

  if (field.conditions || field.condition) {
    const conditions = field.conditions || [field.condition]
    let matchAll = conditions.map((c: any) => _checkConditionsCollection(c, formData, key))

    let result
    if (field.conditionType === 'OR') {
      result = matchAll.filter((res: boolean) => res === true).length > 0
    } else {
      result = matchAll.filter((res: boolean) => res === false).length === 0
    }

    // console.log('result: ', result)
    return result
  }
  return true
}

function _checkConditionsCollection(c:any, formData:any, key:any) {
  const cond = c.selected_checkbox || c.selected_radio || c.field
  let val = formData[key + '/' + cond]
  let condVal = (typeof c.selected_key !== 'undefined') ? c.selected_key : c.value
  if (val === 'true') val = true
  if (val === 'false') val = false
  if (condVal === 'true') condVal = true
  if (condVal === 'false') condVal = false
  let match = val == condVal
  if (c.selected_checkbox && Array.isArray(val)) {
    match = val.includes(condVal)
  }
  // console.log(field.name + ' -> ' + cond + ':\nval -> condVal: ', val, ' -> ', condVal, '\nmatch ', match)
  return typeof val !== 'undefined' && match
}

export function getOptions(field: any, appInfo: any, currentAward: any = null) {
  if (field.options) {
    return field.options
  }
  if ((field.type === 'checkbox' && !field.values) || !field.values) {
    return null
  }
  const path = field.values.split('.')
  let res = appInfo[path[0]]
  if (path[0] === '_custom') {
    // prepare data for special cases, each case needs to be coded separately
    if (path[1] === 'viewingAppointmentDates'
        && currentAward
    ) {
      res = [
        {
          "name": new Date(currentAward.viewingAppointmentDate1).toLocaleDateString('de'),
          "value": currentAward.viewingAppointmentDate1?.substr(0, 10),
        },
        {
          "name": new Date(currentAward.viewingAppointmentDate2).toLocaleDateString('de'),
          "value": currentAward.viewingAppointmentDate2?.substr(0, 10),
        },
      ]
    }
  } else {
    let i = 1
    while (i < path.length) {
      res = res[path[i++]]
    }
  }
  return res
}

export function getFile(field: any, data: any, group: any) {
  // console.log('field, data: ', field, data);
  return data[field.name] || null
}

export function getImage(field: any, data: any, group: any) {
  let subformName = field.subFormCfg
  if (group.subForms && field.subFormCfg && group.subForms[field.subFormCfg]) {
    let model = group.subForms[field.subFormCfg].model.split('.')
    subformName = model[model.length - 1]
  }
  let images = data[subformName]
  if (!images) { // hack for subFormCfg for images
    images = data['imagesDetail']
  }
  if (images?.length > 0) {
    let res = images.filter((img: any) => img.name === field.name && img.id)
    if (res.length > 0) {
      return res.shift()
    }
  }
  return data[field.name] || null
}

export function setImageCoords(field: any, data: any, group: any, coords: any) {
  let subformName = field.subFormCfg
  if (group.subForms && field.subFormCfg && group.subForms[field.subFormCfg]) {
    let model = group.subForms[field.subFormCfg].model.split('.')
    subformName = model[model.length - 1]
  }
  let images = data[subformName]
  if (!images) { // hack for subFormCfg for images
    images = data['imagesDetail']
  }
  if (images?.length > 0) {
    let res = images.filter((img: any) => img.name === field.name && img.id)
    if (res.length > 0) {
      // set coords
      res[0].userCropHeight = coords.height
      res[0].userCropWidth = coords.width
      res[0].userCropX = coords.left
      res[0].userCropY = coords.top
    }
  }
  return null
}

export function getSubformFieldPath(group: any, field: any, subfield: any, formObj: any) {
  let subformName = field.subFormCfg
  if (group.subForms && field.subFormCfg && group.subForms[field.subFormCfg]) {
    let model = group.subForms[field.subFormCfg].model.split('.')
    subformName = model[model.length - 1]
  }
  let index = 0
  if (formObj[subformName]) {
    let success = false
    formObj[subformName].forEach((obj: any, i: number) => {
      if (field.name === obj.name) {
        index = i
        success = true
      }
    })
    if (!success) {
      // we need to create a placeholder for this field
      index = formObj[subformName]
      formObj[subformName].push({ name: field.name })
    }
  }
  if (!subfield) {
    return group.key + '/' + subformName + '[' + index + ']'
  }
  return group.key + '/' + subformName + '[' + index + '].' + subfield.name
}

export function isNotNested(field: any) {
  if (field.type === 'textfield' ||
    field.type === 'textarea' ||
    field.type === 'datetime' ||
    field.type === 'wysiwyg' ||
    field.type === 'checkbox' ||
    field.type === 'select' ||
    field.type === 'radio' ||
    field.type === 'image' ||
    field.type === 'file' ||
    field.type === 'reactiveImage' ||
    field.type === 'conditionalSelect' ||
    field.type === 'previewLink' ||
    field.type === 'buttonLink' ||
    field.type === 'headline1' ||
    field.type === 'headline2' ||
    field.type === 'headline3' ||
    field.type === 'headline4' ||
    field.type === 'headlineInfo' ||
    field.type === 'spacer' ||
    field.type === 'text' ||
    field.type === 'hr') {
    return true
  } else {
    // console.log('isNotNested -> field', field.name, field.type)
    return false
  }
}

export function parseData(formParts: any, data: any, currentFormData: any) {
  // parses incoming JSON and returns formData
  const formData: any = Object.assign({}, currentFormData)
  const subformData: any = {}
  const layouts: any = {}
  let reloadTriggers: any = []

  formParts.forEach((part: any) => {
    // console.log('parseData part: ', part.name);
    part.fields.forEach((field: any) => {
      // match fields to layouts
      if (field.layout) {
        if (!layouts[field.layout]) {
          layouts[field.layout] = []
        }
        layouts[field.layout].push(field)
      }

      // map subform data to formData paths
      if (field.subFormCfg) {
        // const conf = part.subForms[field.subFormCfg]
        let model = part.subForms[field.subFormCfg].model.split('.')
        let subformName = model[model.length - 1]
        let sData = data[subformName]
        if (sData && sData.length > 0) {
          sData.forEach((obj: any, i: number) => {
            // console.log('subformName, i, obj: ', subformName, i, obj);
            Object.keys(obj).forEach((k: any) => {
              const path = part.key + '/' + subformName + '[' + i + '].' + k
              const v = obj[k]
              // try not to overwrite in-memory value set by user
              if (formData[path] === v || typeof v === 'undefined') return
              if (typeof formData[path] === 'undefined' || (!formData[path] && v)) {
                // console.log('######## did overwrite: ', path, formData[path], ' with:', v);
                formData[path] = v
                // } else {
                //   console.log('did not overwrite: ', path, formData[path], ' with:', v);
              }
            })
          })
        } else {
          // TODO: ?
          // data[field.subFormCfg] = [{}]
          // console.log('subFormCfg field: ', subformName, field.name, field);
        }
      }

      // reloadTriggers
      if (field.reloadTrigger) {
        field.reloadTrigger.forEach((k: string) => {
          reloadTriggers.push(k)
        });
      }

      // create placeholder arrays
      if (field.type === 'embedSubformFormMany') {
        if (!subformData[field.name]) {
          subformData[field.name] = [{ _index: 0, name: field.name }]
        }
      }
      if (field.type === 'EmbedMany') {
        if (!formData[field.name]) {
          formData[part.key + '/' + field.name] = [{}]
          if (field.fields) {
            field.fields.forEach((f: any) => {
              if (f.type === 'EmbedMany') {
                formData[part.key + '/' + field.name + '[0].' + f.name] = [{}]
              }
            })
          }
        }
      }

      // map values to formData paths
      let val: any = data[field.name]

      // ref data for each remote function
      if (field.remoteFunction) {
        // let key = part.key + '/' + field.name
        let key = field.name
        if (!subformData.remoteFunction) {
          subformData.remoteFunction = {}
        }
        if (!subformData.remoteFunction[field.remoteFunction]) {
          subformData.remoteFunction[field.remoteFunction] = {}
        }
        subformData.remoteFunction[field.remoteFunction][key] = val
      }

      if (field.preselected && !val) val = field.preselected
      if (field.defaultValue) val = field.defaultValue
      if (typeof val !== 'undefined' && val !== null) {
        if (field.type === 'embedSubformFormMany') {
          val.forEach((obj: any, i: number) => {
            // console.log(field.name + ' obj: ', i, obj);
            if (i > 0) {
              subformData[field.name].push({ _index: i, name: field.name })
            }
            // console.log('subformData[' + field.name + ']: ', subformData[field.name]);
            Object.keys(obj).forEach((k: any) => {
              const v = obj[k]
              formData[part.key + '/' + field.name + '[' + i + '].' + k] = v
            })
          })
        } else if (field.type === 'EmbedMany') {
          // level 1
          if (val.length < 1) val.push({})
          val.forEach((obj: any, i: number) => {
            if (Object.keys(obj).length > 0) {
              Object.keys(obj).forEach((k: any) => {
                const v = obj[k]
                formData[part.key + '/' + field.name + '[' + i + '].' + k] = v
              })
            }
          })
          formData[part.key + '/' + field.name] = val

          // nested array fields in nested array fields, danke ivo :P
          if (field.fields) {
            field.fields.forEach((f: any) => {
              // level 2
              val.forEach((v: any, i: number) => {
                const val2: any = v[f.name]
                if (val2) {
                  if (val2.length === 0) {
                    // empty arrays need at least one data-obj
                    val2.push({})
                  }
                  if (f.type === 'EmbedMany') {
                    // EmbedMany
                    val2.forEach((obj: any, j: number) => {
                      Object.keys(obj).forEach((k: any) => {
                        const v = obj[k]
                        formData[part.key + '/' + field.name + '[' + i + '].' + f.name + '[' + j + '].' + k] = v
                      })
                    })
                    formData[part.key + '/' + field.name + '[' + i + '].' + f.name] = val2
                  } else if (f.type === 'embedSubformFormOne') {
                    // embedSubformFormOne
                    if (typeof val2 === 'object') {
                      Object.keys(val2).forEach((k: any) => {
                        const v = val2[k]
                        formData[part.key + '/' + field.name + '[' + i + '].' + f.name + '.' + k] = v
                      })
                    }
                  }
                }
              })
            })
          }
        } else if (field.type === 'embedSubformFormOne') {
          // embedSubformFormOne
          Object.keys(val).forEach((k: any) => {
            const v = val[k]
            formData[part.key + '/' + field.name + '.' + k] = v
          })
        } else {
          // regular field
          const path = part.key + '/' + field.name
          // try not to overwrite in-memory value set by user
          if (formData[path] === val) return
          if (typeof formData[path] === 'undefined' || (!formData[path] && val) || (formData[path] && val.length > 0)) {
            formData[path] = val
            // } else {
            //   console.log('did not overwrite: ', path, formData[path], ' with:', val);
          }
        }
        // } else {
        //   console.log(field.name + ' val: ', val);
      }
    })
  })

  // make reloadTriggers unique
  const tmp: any = {};
  reloadTriggers.forEach(function (v: any) {
    tmp[v + '::' + typeof v] = v;
  });
  reloadTriggers = Object.keys(tmp).map(function (v) {
    return tmp[v];
  });

  return { formData, subformData, layouts, data, reloadTriggers }
}

export function parseForm(formParts: any, formData: any,) {
  // parses formData and returns JSON
  let data: any = {}
  formParts.forEach((part: any) => {
    if (!data[part.key]) data[part.key] = {}
  })
  Object.keys(formData).forEach((key: string) => {
    const s = key.split('/')
    const part = s[0]
    const path = s[1]
    let val = formData[key]

    if (path.indexOf('].') >= 0) {
      const subformMany = path.split('[')
      const index = parseInt(subformMany[1].split('].')[0], 10)
      const name = subformMany[1].split('].')[1]

      if (subformMany[2]) {
        // EmbedMany -> EmbedMany field
        const index2 = parseInt(subformMany[2].split('].')[0], 10)
        const name2 = subformMany[2].split('].')[1]
        if (!data[part][subformMany[0]]) data[part][subformMany[0]] = []
        if (!data[part][subformMany[0]][index]) data[part][subformMany[0]][index] = {}
        if (!data[part][subformMany[0]][index][name]) data[part][subformMany[0]][index][name] = []
        if (!data[part][subformMany[0]][index][name][index2]) data[part][subformMany[0]][index][name][index2] = {}
        data[part][subformMany[0]][index][name][index2][name2] = val
      } else if (name.indexOf('.') >= 0) {
        // EmbedMany -> embedSubformFormOne field
        const subformOne = name.split('.')
        if (!data[part][subformMany[0]]) data[part][subformMany[0]] = []
        if (!data[part][subformMany[0]][index]) data[part][subformMany[0]][index] = {}
        if (!data[part][subformMany[0]][index][subformOne[0]]) data[part][subformMany[0]][index][subformOne[0]] = {}
        data[part][subformMany[0]][index][subformOne[0]][subformOne[1]] = val
      } else {
        // EmbedMany -> regular field
        if (!data[part][subformMany[0]]) data[part][subformMany[0]] = []
        if (!data[part][subformMany[0]][index]) data[part][subformMany[0]][index] = {}
        data[part][subformMany[0]][index][name] = val
      }
    } else if (path.indexOf('.') >= 0) {
      // embedSubformFormOne field
      const subformOne = path.split('.')
      if (!data[part][subformOne[0]]) data[part][subformOne[0]] = {}
      data[part][subformOne[0]][subformOne[1]] = val
    } else {
      // regular field
      data[part][path] = val
    }
  })
  return data
}

export function addRow(field: any, index: number, sfd: any, path: string = '') {
  if (!path) path = field.name
  let subformData: any = {}
  let obj: any = {}
  subformData[path] = sfd[path]

  // if field contains further array we need to prepare those too
  if (field.fields) {
    field.fields.forEach((f: any) => {
      if (f.type === 'EmbedMany') {
        obj[f.name] = subformData[path + '[' + (index + 1) + '].' + f.name] = [{}]
      }
    })
  }

  subformData[path].splice(++index, 0, obj)
  subformData = Object.assign({}, sfd, subformData)

  return subformData
}

export function removeRow(field: any, index: number, fd: any, sfd: any, path: string = '') {
  if (!path) path = field.name
  let formData: any = fd
  let subformData: any = {}

  // remove iterator placeholder
  subformData[path] = sfd[path] || fd[path]
  subformData[path].splice(index, 1)
  subformData = Object.assign({}, sfd, subformData)

  // delete data
  Object.keys(formData).forEach((key: string) => {
    if (key.indexOf(path + '[' + index + ']') >= 0) {
      delete formData[key]
    }
  })
  formData = Object.assign({}, fd, formData)

  return { formData, subformData }
}
