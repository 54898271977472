
import Vue from 'vue'

export default Vue.extend({
  name: 'FormCheckbox',

  data: () => {
    const array: any[] = []
    const isOptionActive: any = {}
    const names: any = {}
    return {
      array,
      isOptionActive,
      names,
    };
  },

  props: [
    'description',
    'errors',
    'field',
    'fields',
    'label',
    'name',
    'options',
    'validation',
    'value',
  ],

  computed: {
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return (this.$parent as any).currentAward
    },
    reloadTriggers () {
      return this.$store.state.reloadTriggers
    },
  },

  methods: {
    checkErrors(name: string, value: string, index: number) {
      if (this.errors && this.errors.length > 0) {
        let e = this.errors.filter((err: any) => {
          if (err.field && err.field.startsWith(name)) {
            if (err.field.startsWith(name + '[' + index + ']')) return true
          }
          return false
        })
        // console.log('### ', name, value, '\nError', e.length > 0);
        if (e.length > 0) return 'error'
      }
    },

    emitInput() {
      this.$emit('input', this.array.map((o) => {
        const res = {
          selected: true,
          name: this.names[o],
          value: o,
          text: this.isOptionActive[o] || ''
        }
        return res
      }))
    },

    emitSubfield(field: string, option: any, el: any) {
      const hasVal = !!el.value
      this.isOptionActive[option.value] = el.value
      this.isOptionActive = Object.assign({}, this.isOptionActive)
      setTimeout(() => {
        this.emitInput()
      }, 100)

      // also toggle selected for textfield only subs
      const i = this.array.indexOf(option.value);
      if (hasVal) {
        if (i === -1) this.array.push(option.value);
      } else {
        if (i !== -1) this.array.splice(i, 1);
      }
    },

    getVal(option: any) {
      let res = option.value
      if (typeof res === 'undefined') {
        res = option.name
      }
      if (res && typeof res.toString === 'function') {
        res = res.toString()
      }
      return res
    },

    isChecked(option: any) {
      if (option.type === 'textfield' || option.type === 'textarea') return true
      let val = this.getVal(option)
      if (this.options) {
        let res = this.array.includes(val)
        // console.log('val, res: ', val, res);
        return res
      } else {
        return this.value == val
      }
    },

    parseLabel(label: string) {
      let res: string = label
      if (res.includes('##pdfBedingungenPath##')) {
        res = res.replace('##pdfBedingungenPath##', this.currentAward.pdfBedingungenPath)
        res = res.replace('href="/pdf', 'href="' + this.baseUrl + 'pdf')
      }
      return res
    },

    updateInput(el: any) {
      setTimeout(() => {
        if (this.options) {
          if (this.fields) {
            // this is a checkbox with additional fields
            this.emitInput()
          } else {
            this.$emit('input', this.array)
          }
        } else {
          this.$emit('input', el.checked)
        }
        if (this.reloadTriggers.includes(this.name)) {
          this.$store.commit('triggerSaveForm', true)
        }
      }, 100)
    }
  },

  // watch: {
  //   value (newVal) {
  //     console.log('checkbox newVal: ', newVal);
  //   },
  // },

  mounted () {
    if (this.value && typeof this.value === 'object') {
      if (this.fields) {
        // map incoming subfields and values, make sure they're unique
        const arr: string[] = this.value.map((v: any) => v.value)
        this.options.forEach((o: any) => {
          this.names[o.value] = o.name
        })
        const tmp: string[] = []
        this.array = arr.filter((v: string) => {
          // TODO: could be removed once we're sure no duplicates can appear
          if (tmp.indexOf(v) < 0) {
            tmp.push(v)
            return true
          }
          return false
        })
        this.isOptionActive = {}
        this.value.forEach((v: any) => {
          this.isOptionActive[v.value] = v.text
        })
      } else {
        if (Array.isArray(this.value) && this.value.length > 0 && typeof this.value[0] === 'object') {
          let map = this.value.map(o => o.id.toString())
          this.array = map
        } else {
          this.array = this.value
        }
        this.$emit('input', this.array)
      }
    }
  },
})
