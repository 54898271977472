
import Vue from 'vue'
import { getFile, getImage, getOptions, setImageCoords } from '../../mixins/formData'

export default Vue.extend({
  name: 'FormRow',

  data: () => {
    return {
      formNames: {
        awards: 'award_admin_crud',
        projects: 'projekt_admin_crud',
        sponsors: 'awardsponsor_admin_crud',
        users: 'user_admin_crud',
      } as any,
    };
  },

  props: [
    'errors',
    'formData',
    'field',
    'group',
    'label',
    'layout',
    'fname',
    'options',
    'path',
    'subformField',
  ],

  computed: {
    appInfo () {
      return (this.$store as any).state.appInfo
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return (this.$parent as any).currentAward
    },
    currentPart () {
      return (this.$parent as any).currentPart
    },
    formObj () {
      return (this.$parent as any).formObj
    },
    id () {
      return (this.$parent as any).id
    },
    initCounter () {
      return (this.$parent as any).initCounter
    },
    name () {
      return this.fname || this.path?.split('/')[1]
    },
    formName () {
      return (this.$parent as any).formName
    },
  },

  methods: {
    checkErrors(field: any) {
      if (this.errors && this.errors.length > 0) {
        if (this.subformField) {
          // console.log('this.subformField: ', this.subformField);
          let e1 = this.errors.filter((err: any) => {
            return err.field.indexOf('{name:' + this.subformField?.name + ',' + field.name) >= 0
          })
          if (e1.length > 0) return 'error'
        }
        if (this.path.indexOf('].') >= 0) return false
        let e2 = this.errors.filter((err: any) => err.field && err.field === field.name)
        let e3 = this.errors.filter((err: any) => {
          return err.field.indexOf('{name:' + field.name) >= 0 && err.field.indexOf(',') < 0
        })
        if (e2.length > 0 || e3.length > 0) return 'error'
      }
    },

    filterErrors(errors: any) {
      if (errors && errors.length > 0) {
        // rough pre-filtering
        let e = errors.filter((err: any) => {
          return err.field.indexOf(this.field.name) >= 0
        })
        return e
      }
    },

    cropImage (coordinates: any) {
      if (coordinates) {
        this.formData[this.path + '.' + 'userCropHeight'] = coordinates.height || 0
        this.formData[this.path + '.' + 'userCropWidth'] = coordinates.width || 0
        this.formData[this.path + '.' + 'userCropX'] = coordinates.left || 0
        this.formData[this.path + '.' + 'userCropY'] = coordinates.top || 0
      }

      // also set coords on formObj
      setImageCoords(this.field, this.formObj, this.group, coordinates)
    },

    prepareLink(url: string) {
      const { awardType, year } = this.currentAward
      url = url.replace('-AWARDTYPE-', awardType)
      url = url.replace('-YEAR-', year)
      url = url.replace('-ID-', this.id)
      return this.baseUrl.slice(0, -1) + url
    },

    prepareSrc(url: string) {
      const { awardType, year } = this.currentAward
      url = url.replace('{awardType}', awardType)
      url = url.replace('{year}', year)
      url = url.replace('{id}', this.id)
      return this.baseUrl.slice(0, -1) + url
    },

    openLink(event: Event) {
      if (this.field.linkType === 'preview') {
        let target = (event.target as HTMLLinkElement)
        event.preventDefault()
        this.$store.commit('triggerSaveForm', true)
        setTimeout(() => {
          window.open(target.href, '_blank', 'noopener noreferrer')
        }, 1000)
      }
    },

    async saveFile(event: Event, fieldName: string) {
      const isOffice = this.$route.name === 'nutzerdaten'
      let target = (event.target as HTMLInputElement)
      if (target.files !== null && target.files.length > 0) {
        this.$store.commit('setProcessing', true)
        let file = target.files[0]
        const p: any = this.$parent
        const { awardType, year } = this.currentAward

        let data: any = {
          awardType,
          fieldName,
          file,
          year,
          id: this.id,
          part: this.currentPart,
          URL: (p.type && p.type === 'awards') ?
            `file/upload/pdf/award/${this.id}/award_admin_crud/part1/${fieldName}` : ''
        }
        if (isOffice) {
          // different URL for office
          data.URL = `file/upload/pdf/office/${awardType}/${year}/${this.formName}/${this.currentPart}/${fieldName}`
        } else if ((this.$parent as any).saveProject) {
          // trigger autosave for projects
          (this.$parent as any).saveProject(true)
        }

        this.$store
          .dispatch('saveFile', data)
          .then(file => {
            console.log('saveFile -> file', file)
            if (file) {
              setTimeout(() => {
                this.$emit('refreshFormObj', true)
              }, 333)
            } else {
              this.formData[this.path] = target.value = ''
            }
            this.$store.commit('setProcessing', false)
          })
      }
      return false
    },

    async saveImage(event: Event, fieldName: string) {
      let target = (event.target as HTMLInputElement)
      if (target.files !== null && target.files.length > 0) {
        this.$store.commit('setProcessing', true)
        let file = target.files[0]
        const p: any = this.$parent
        const { awardType, year } = this.currentAward

        let data: any = {
          awardType,
          fieldName,
          file,
          year,
          id: this.id,
          part: this.currentPart,
          URL: (p.type && p.type === 'awards') ?
            `file/upload/image/award/${this.id}/award_admin_crud/part1/${fieldName}` :
            (p.type && p.type === 'sponsors') ?
            `file/upload/image/awardsponsor/${this.id}/awardsponsor_admin_crud/part1/${fieldName}` : ''
        }

        this.$store
          .dispatch('saveImage', data)
          .then(img => {
            console.log('saveImage -> img', img)
            if (img) {
              setTimeout(() => {
                this.$emit('refreshFormObj', true)
              }, 333)
            } else {
              this.formData[this.path] = target.value = ''
            }
            this.$store.commit('setProcessing', false)
          })
      }
      return false
    },

    checkRemoteFunction (data: any) {
      if (this.field && this.field.remoteFunction) {
        this.$emit('remoteFunction', {
          name: this.field.remoteFunction,
          // path: this.path,
          path: this.name,
          value: data
        })
      }
    },

    async removeFile(event: Event, fieldName: string) {
      this.$store
        .dispatch('deleteFile', {fieldName, id: this.formObj.id})
        .then(file => {
          console.log('removeFile -> file', file)
          this.formData[this.path + '.path'] = null
          setTimeout(() => {
            this.$emit('refreshFormObj', true)
          }, 333)
        })
      return false
    },

    async removeImage(event: Event, fieldName: string) {
      const { awardType, year } = this.currentAward
      let type = 'projekt'
      if (this.$route.params.type === 'awards') {
        type = 'award'
      } else if (this.$route.params.type === 'sponsors') {
        type = 'awardsponsor'
      }

      let formName = 'user_project_save'
      if (this.$route.params.type) {
        formName = this.formNames[this.$route.params.type]
      }

      this.$store
        .dispatch('deleteImage', {
          fieldName,
          formName,
          part: this.currentPart,
          type,
          awardType,
          year,
          id: this.formObj.id,
        })
        .then(img => {
          console.log('removeImage -> img', img)
          this.formData[this.path + '.path'] = null
          setTimeout(() => {
            this.$emit('refreshFormObj', true)
          }, 333)
        })
      return false
    },

    getFile,
    getImage,
    getOptions,
  },

  mounted () {
    console.log(this.field)
  },
});
